import React from "react";

function Error404() {
    return (
        <div>
            <h1>Required resource is not Available. Please check the requested URL.</h1>
        </div>
    )
}

export default Error404;