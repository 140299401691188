import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import Axios from "axios";
import {ReactSession} from "react-client-session";
import ArrowHeader from "./arrowHeader";
// import {useHistory} from "react-router";
// import {withRouter} from "react-router-dom";

const NewApp=props=>{
    const history=useHistory();
    const [student,setStudent]=useState({
        student_name:"",student_fathername:"",student_dob:"",student_gender:"",
        student_mobile:"",student_email:"",student_batch:"",student_cpassword:""
    });
    //let errorsObj={stduent_email:'',student_password:''};
    //const [errors,setErrors]=useState(errorsObj);

    let eleName,value;
    const handleInputs=(e)=>{

        eleName=e.target.name;
        value=e.target.value;
        setStudent({...student,[eleName]:value});
    }

    const validatePassword=()=>{

        var p=document.getElementById("password");
        var c=document.getElementById("cpassword");
        if(p.value!==c.value)
        {
            console.log(c.value,p.value)
            c.setCustomValidity("Password not matched");
            c.focus();
        }
        else{
            c.setCustomValidity("");
        }
    }

    const postStudentBasic=async (e)=>{
        var response={};
        e.preventDefault();

        const {student_name,student_fatherName,student_dob,student_gender,
            student_mobile,student_email,student_batch}=student;
        const res=await Axios.post(`${process.env.REACT_APP_URI}student/basic`,{
            student_name:student.student_name,
            student_fatherName:student.student_fatherName,
            student_dob:student.student_dob,
            student_gender:student.student_gender,
            student_mobile:student.student_mobile,
            student_email:student.student_email,
            student_batch:student.student_batch,
            student_password:student.student_password,
        },{

            headers:{
                "Content-Type":"application/json",
            },
            // body:JSON.stringify({
            //     student_name,student_fathername,student_dob,student_gender,
            //     student_mobile,student_email,student_batch
            // })
        }).then((res)=>
        {
            if(res.status===500){
                alert("Either You are already registered or something went wrong");
            }
            response=res.data;
             console.log(res);
            ReactSession.setStoreType("localStorage");
            ReactSession.set("session_status","Active");
            ReactSession.set("session_role","Student");
            ReactSession.set("student_email",student.student_email);
            ReactSession.set("student_id",res.data.student_id);
        }).catch(err=>{
            if(err.code=="ERR_BAD_RESPONSE"){
                alert("Either You are already registered or something went wrong. We are redirecting you to login panel");
            }
        });

        if(!response || response.status>=400){
            console.log(response);
            alert("Please check the form again");
        }else{
           history.push("/student/dashboard");
            //alert("Registration Successful");
        }

    }
    return(
        <>
            <ArrowHeader form="basic"  />
            <div className="container">
                <div className="row">
                    <div className="col-md-3">&nbsp;</div>
                    <div className="col-md-6">
                        <div className="card border-secondary mt-3 text-justify">
                            <div className="card-header"><h4 className="card-title"> New Student Registration</h4></div>
                            <div className="card-body">
                                <div className="container form-floating">
                                    <form method="POST" id="form-studentbasic" onSubmit={postStudentBasic}>
                                    <div className="row">
                                        <div className="col-md-12 ">
                                            <label>Student Name</label>
                                            <input type="text" name="student_name" className="form-control"
                                            value={student.student_name} onChange={handleInputs} required={true}
                                            pattern="[a-zA-Z'-'\s]*"
                                            />
                                        </div>
                                        <div className="col-md-12 ">
                                            <label className="mt-3">Father's Name</label>
                                            <input type="text" name="student_fatherName" className="form-control"
                                                   value={student.student_fatherName} onChange={handleInputs}
                                                   required={true}
                                                   pattern="[a-zA-Z'-'\s]*"
                                            />
                                        </div>
                                        <div className="col-md-6 ">
                                            <label className="mt-3">Date of Birth</label>
                                            <input type="date" name="student_dob" className="form-control"
                                                   value={student.student_dob} onChange={handleInputs}
                                                   required={true} max="2010-01-01"
                                            />
                                        </div>
                                        <div className="col-md-6 ">
                                            <label className="mt-3">Gender</label>
                                            <select name="student_gender" className="form-control"
                                                   value={student.student_gender} onChange={handleInputs}
                                                    required={true}
                                            >
                                                <option value="0">--select gender--</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                                <option value="Transgender">Transgender</option>
                                            </select>

                                        </div>
                                        <div className="col-md-6 ">
                                            <label className="mt-3">Mobile No.</label>
                                            <input type="text" name="student_mobile" className="form-control"
                                                   value={student.student_mobile} onChange={handleInputs}
                                                   placeholder="10 digits mobile number"
                                                   pattern="[0-9]{10,10}"
                                                   minLength={10}
                                                   maxLength={10}
                                                   required={true}
                                                   onInvalid={e=>e.target.setCustomValidity("Wrong mobile number")}
                                            />
                                        </div>

                                        <div className="col-md-6 ">
                                            <label className="mt-3">Email ID</label>
                                            <input type="email" name="student_email" className="form-control"
                                                   value={student.student_email} onChange={handleInputs}
                                                   required={true}
                                            />
                                        </div>
                                        <div className="col-md-6 ">
                                            <label className="mt-3">Password</label>
                                            <input type="password" name="student_password" id="password" className="form-control"
                                                   value={student.student_password} onChange={handleInputs}
                                                   required={true}
                                            />
                                        </div>
                                        <div className="col-md-6 ">
                                            <label className="mt-3">Confirm Password</label>
                                            <input type="password" name="student_cpassword" id="cpassword" className="form-control"
                                                   value={student.student_cpassword} onChange={handleInputs}
                                                   required={true} onBlur={validatePassword}
                                            />
                                        </div>
                                            <button type="submit" className="btn btn-sm btn-success mt-3 mx-2" > Submit Information</button>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">&nbsp;</div>
                </div>
            </div>
        </>
    )
}

export default NewApp;