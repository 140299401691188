import React,{useState} from "react";
import Axios from 'axios';
import {useHistory} from "react-router-dom";


const ForgotPassword=()=>{
    const history=useHistory();
    let eleName,value;
    const [student,setStudent]=useState({
        student_name:"",student_fathername:"",student_dob:"",student_gender:"",
        student_mobile:"",student_email:"",student_batch:"",
    });

    const handleInputs=(e)=>{
        e.preventDefault();
        eleName=e.target.name;
        value=e.target.value;
        setStudent({...student,[eleName]:value});
    }


    const postGetPassword=async ()=>{
    //get the student details by email and mobile
        const student=await Axios.get(`${process.env.REACT_APP_URI}student/detail/one/${student.student_mobile}/${student.student_email}`).then((res)=>{
            console.log(res.data)
        }).catch(err=>{
            console.log(err.message);
        });
        // if(student!==null){
        //     await Axios.put(`${process.env.REACT_APP_URI}student/basic/update/${student.student_email}`).then((res)=>
        //     {
        //         console.log("Password Updated successfully")
        //     })
        // }
    }

    return(
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-3">&nbsp;</div>
                    <div className="col-md-6">
                        <div className="card border-secondary mt-3 text-justify">
                            <div className="card-header"><h4 className="card-title"> Forgot Password</h4></div>
                            <div className="card-body">
                                <div className="container form-floating">
                                    <form method="POST" id="form-studentbasic">
                                        <div className="row">

                                            <div className="col-md-12 ">
                                                <label className="mt-3">Email ID</label>
                                                <input type="email" name="student_email" className="form-control"
                                                       value={student.student_email} onChange={handleInputs}
                                                />
                                            </div>
                                            <div className="col-md-12 ">
                                                <label className="mt-3">Mobile</label>
                                                <input type="text" name="student_mobile" className="form-control"
                                                       value={student.student_mobile} onChange={handleInputs}
                                                />
                                            </div>
                                            <div className="col-md-12 ">
                                                <label className="mt-3">New Password</label>
                                                <input type="password" name="student_password" className="form-control"
                                                       value={student.student_password} onChange={handleInputs}
                                                />
                                            </div>


                                            <button onClick={postGetPassword} className="btn btn-sm btn-success mt-3 mx-2" > Get Login</button>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">&nbsp;</div>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword;