import React, {useState,useEffect} from 'react';
import Axios from "axios";
import {ReactSession} from "react-client-session";
import {Link, useHistory} from "react-router-dom";
import ArrowHeader from "../arrowHeader";
import {IsValidLogin} from "../isLoggedIn";
// import {useEffect} from "@types/react";
{/*Steps to be done by the students
1. For every new stream student need to pay registration fee.
2. after successful registration fee payment student will be able to apply for the stream */}

const StudentApplied=()=>{
    const history=useHistory();
    ReactSession.setStoreType("localStorage");
    {/*Setup an object holding every object value fecthed from the DB regarding the reg fee*/}
    const [registrationFeeStatus,setRegistrationFeeStatus]=useState(false)
    const [convFeeStatus,setConvFeeStatus]=useState(false);
    const [studentid,setStudentID]=useState(0);
    const [streamid,setStreamID]=useState(0);
    const [collegeid,setCollegeID]=useState(0);
    const [stream,setStream]=useState([{
        stream_id:0,stream_name:"",stream_description:""
    }]);
    // const[streamid,setStreamID]=useState(0);
    const[streams,setStreams]=useState([]);
    //get Stream Details college wise
    const getStreamOffers=async (e)=>{

        const college_id=e.target.value;
        await Axios.get(`${process.env.REACT_APP_URI}college/${college_id}`).then((res)=>{
            ReactSession.set("college_code",res.data.college_code)
        })
        const uri=`${process.env.REACT_APP_URI}college/coffers/${college_id}`;
        // alert(uri)
        const getclgstream=await Axios.get(uri);
        const data=getclgstream.data;
        console.log(getclgstream);
        setCollegeID(college_id);
        ReactSession.set("collegeid",college_id);
        setStreams(data);
        getConvFeeStatus();
        console.log(college_id)
        console.log(data);
    }
    // let eleName,value;
    // const handleInputs=(e)=>{
    //     eleName=e.target.name;
    //     value=e.target.value;
    //     setStream({...stream,[eleName]:value});
    // }

    useEffect(() => {
        if(!IsValidLogin()){
            // console.log("entered");
            localStorage.clear();
            history.push("/");
        }
            getColleges();
            getSubjects();
            getGroups();
            getSubjectsOptional();
            getSubjectsCore();
            getSubjectsElective();
            // getRegistrationFeeStatus();
            //getConvFeeStatus();
    },[])


    const getStreamDetails=async (stream_id)=>{
        const api=`${process.env.REACT_APP_URI}stream/${stream_id}`;
        const streamDetails=await Axios.get(api);
        return streamDetails.data
    }



    const [colleges,setColleges]=useState([]);
    const getColleges=async ()=>{
        const res=await Axios.get(`${process.env.REACT_APP_URI}college`);
        const getclg= res.data;

        setColleges(getclg);
    }


    const getConvFeeStatus=async (e)=>{
        if(ReactSession.get("student_id")===null)
        {
            alert("You are not logged in please do login");
            history.push("/")
        }
        const collegeid=ReactSession.get("collegeid")
        const uri=`${process.env.REACT_APP_URI}fee/conv/status/${ReactSession.get("student_id")}/${collegeid}`;
        console.log(uri);
        const convFeeStatus=await Axios.get(uri);
        setConvFeeStatus(convFeeStatus.data.isConvPaid);
        ReactSession.set("ConvFeeStatus",convFeeStatus.data.isConvPaid);
    }

    const [subjects,setSubjects]=useState([{}]);

    const getSubjects=async ()=>{

        await Axios.get(`${process.env.REACT_APP_URI}subject`).then((res)=>{
            setSubjects(res.data);
        }).catch(err=>{
            console.log(err.message);
        })
    }
    const [coresubjects,setCoreSubjects]=useState([{}]);

    const getSubjectsCore=async ()=>{
        await Axios.get(`${process.env.REACT_APP_URI}subject/select/CORE`).then((res)=>{
            setCoreSubjects(res.data);
        }).catch(err=>{
            console.log(err.message);
        })
    }

    const [optionalsubjects,setOptionalSubjects]=useState([{}]);
    const [electivesubjects,setElectiveSubjects]=useState([{}]);

    const getSubjectsElective=async ()=>{

        await Axios.get(`${process.env.REACT_APP_URI}subject/select/ELECTIVE`).then((res)=>{
            setElectiveSubjects(res.data);
        }).catch(err=>{
            console.log(err.message);
        })
    }

    const getSubjectsOptional=async ()=>{
        await Axios.get(`${process.env.REACT_APP_URI}subject/select/OPTIONAL`).then((res)=>{
            setOptionalSubjects(res.data);
        }).catch(err=>{
            console.log(err.message);
        })
    }

    const [groups,setGroups]=useState([{}]);

    const getGroups=async ()=>{

        await Axios.get(`${process.env.REACT_APP_URI}subject/group`).then((res)=>{
            setGroups(res.data);
        }).catch(err=>{
            console.log(err.message);
        })
    }

    //select groups as per stream
    const [grpList,setGrpList]=useState([{}]);
    const getGroupList=async (stream_id)=>{
        await Axios.get(`${process.env.REACT_APP_URI}subject/stream/group/${stream_id}`).then((res)=>{
            setGrpList(res.data);
        }).catch(err=>{
            console.log(err.message);
        })
    }

    // const [islist,setIsList]=
    const[grpsubject,setGrpSubject]=useState([{}]);
    const getSubjectGroup=async (e)=>{
        var sg_id=e.target.value;
        ReactSession.set("group_id", sg_id);
        var core1=document.getElementById("core1_id").value;
        var core2=document.getElementById("core2_id").value;
        var optional1=document.getElementById("optional1_id").value;
        ReactSession.set("core1_id",core1)
        ReactSession.set("core2_id",core2)
        ReactSession.set("optional1_id",optional1)
        ReactSession.set("group_id",sg_id)
        await Axios.post(`${process.env.REACT_APP_URI}student/select/subject`,{
            stream_id:ReactSession.get("streamid"),
            college_id:ReactSession.get("collegeid"),
            student_id:ReactSession.get("student_id"),
            group_id:sg_id,
            core1_id:core1,
            core2_id:core2,
            optional1_id:optional1,
        }).then((res)=>{
            console.log(res)
        }).catch(err=>{
            console.log(err.message);
        })
        await Axios.get(`${process.env.REACT_APP_URI}subject/group/subject/${sg_id}`).then((res)=>{
            setGrpSubject(res.data);
            console.log(res.data);
        }).catch(err=>{
            console.log(err.message);
        })
    }

    const getRegistrationFeeStatus=async (e)=>{
        if(e.target.name==="stream_id") {
            const streamid1 = e.target.value;
            getGroupList(streamid1)
            // alert(streamid1);
            setStreamID(streamid1)
            // alert("We are redirecting you to next page for the payment.")
            ReactSession.set("streamid", streamid1);//perfectly fine till
            //streamid is not reflecting
            const uri = `${process.env.REACT_APP_URI}fee/reg/status/${ReactSession.get("student_id")}/${e.target.value}/${collegeid}`;
            console.log(uri);
            const regFeeStatus = await Axios.get(uri);
            ReactSession.set("RegFeeStatus", regFeeStatus.data.isPaid);
            setRegistrationFeeStatus(regFeeStatus.data.isPaid);
            console.log(registrationFeeStatus);
        }
        else{
            console.log("No Stream Selected")
        }
    }



    return(
        <>
            <ArrowHeader form="stream"  />
            <div className="container">
                <div className="row">
                    <div className="col-md-3">&nbsp;</div>
                    <div className="col-md-6">
                        <div className="card border-secondary mt-3 text-justify">
                            <div className="card-header"><h4 className="card-title"> Apply for Stream</h4></div>
                            <div className="card-body">
                                <div className="container form-floating">
                                    <form method="POST" id="form-admin" >
                                        <div className="row">
                                            <div className="col-md-12 ">
                                                <label>College Name</label>
                                                <select  name="college_id" className="form-control"
                                                         // onChange={handleInputs} value={selectDefault}
                                                    onChange={getStreamOffers}
                                                    >
                                                    <option value="0">--Select College--</option>
                                                    {colleges.map(item => {
                                                        console.log(item);
                                                        return <option key={item.college_id} value={item.college_id}> {item.college_name}</option>
                                                    })}
                                                </select>
                                            </div>
                                            {/*Streams offered by the college*/}
                                            <div className="col-md-6 ">
                                                <label>Stream Offers</label>
                                                <select  name="stream_id" className="form-control"
                                                    onChange={getRegistrationFeeStatus}
                                                >
                                                    <option value="0">--Select Stream--</option>
                                                    { streams.length>0?
                                                        streams.map(item => {
                                                     ///   console.log(item);
                                                        return <option key={item.stream_id} value={item.stream_id}> {item.stream_name}</option>
                                                    }): <option > No Stream Found</option>
                                                        }
                                                </select>
                                            </div>

                                            {/*Core 1 offered by the college*/}
                                            <div className="col-md-6 ">
                                                <label>Core Subject</label>
                                                <select  name="core1_id" id="core1_id" className="form-control"
                                                         onChange={getSubjectsCore}
                                                >
                                                    <option value="0">--Select Subject--</option>
                                                    { coresubjects.length>0?
                                                        coresubjects.map(item => {
                                                            ///   console.log(item);
                                                            return <option key={item.subject_id} value={item.subject_id}> {item.subject_name}</option>
                                                        }): <option > No Subjects Found</option>
                                                    }
                                                </select>
                                            </div>
                                            {/*Core 2 offered by the college*/}
                                            <div className="col-md-6 ">
                                                <label>Elective Subject</label>
                                                <select  name="core2_id" id="core2_id" className="form-control"
                                                         onChange={getSubjectsElective}
                                                >
                                                    <option value="0">--Select Subject--</option>
                                                    { electivesubjects.length>0?
                                                        electivesubjects.map(item => {
                                                            ///   console.log(item);
                                                            return <option key={item.subject_id} value={item.subject_id}> {item.subject_name}</option>
                                                        }): <option > No Subjects Found</option>
                                                    }
                                                </select>
                                            </div>

                                            {/*Optional 1 offered by the college*/}
                                            <div className="col-md-6 ">
                                                <label>Optional Subject</label>
                                                <select  name="optional1_id" id="optional1_id" className="form-control"
                                                         onChange={getSubjectsOptional}
                                                >
                                                    <option value="0">--Select Subject--</option>
                                                    { optionalsubjects.length>0?
                                                        optionalsubjects.map(item => {
                                                            ///   console.log(item);
                                                            return <option key={item.subject_id} value={item.subject_id}> {item.subject_name}</option>
                                                        }): <option > No Subjects Found</option>
                                                    }
                                                </select>
                                            </div>

                                            {/*Group offered by the college*/}
                                            <div className="col-md-12 ">
                                                <label>Compulsory</label>
                                                <select  name="group_id" id="group_id" className="form-control"
                                                         onChange={getSubjectGroup}
                                                >
                                                    <option value="0">--Select Group--</option>
                                                    { grpList.length>0?
                                                        grpList.map(item => {
                                                            console.log(item);
                                                            return <option key={item.subjectgroup_id} value={item.subjectgroup_id}> {item.group_title}</option>
                                                        }): <option > No Subjects Found</option>
                                                    }
                                                </select>
                                            </div>

                                            <div className="col-md-12">
                                                <table className="table table-hover table-bordered">
                                                    <tr>
                                                        <th>Subject Name</th>
                                                    </tr>
                                                    {
                                                        grpsubject.map(item=>{
                                                            return(
                                                                <tr>
                                                                    <td>{item.subject_name}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </table>
                                            </div>

                                            <div className="col-md-6 ">
                                                <label>Convenience Fee Status</label><br/>
                                                {

                                                    convFeeStatus?
                                                        <Link to="#" name="confee_status" className="btn btn-sm btn-success btn-block"
                                                            // onChange={handleInputs} value={selectDefault}
                                                        >Conv. Fee Paid Successfully
                                                        </Link>:
                                                        <Link to={`/student/ConvFee/Payment/${ReactSession.get("student_id")}/${ReactSession.get("collegeid")}`}  name="confee_status" className="btn btn-sm btn-danger btn-block"
                                                            // onClick={gotoConvFeeOption}
                                                        >Proceed for Conv. Fee Payment
                                                        </Link>
                                                }

                                                {/*check the registration fee status
                                                    1. if Paid then show Already Paid and display the amount
                                                    2. if unpaid then show pay first option then goto step 3
                                                    3. after successfull payment all the information stored into the local storage and change the state.*/}


                                            </div>
                                            <div className="col-md-6 ">
                                                <label>Prospectus Fee Status</label><br/>
                                                {

                                                        convFeeStatus && registrationFeeStatus?
                                                            <Link to="/student/confirm/detail" name="fee_status" className="btn btn-sm btn-success btn-block"
                                                                // onChange={handleInputs} value={selectDefault}
                                                            >Show Application form
                                                            </Link>
                                                            :
                                                            <Link to={`/student/RegFee/Payment/${ReactSession.get("student_id")}/${streamid}/${ReactSession.get("collegeid")}`}  name="fee_status" className="btn btn-sm btn-danger btn-block"
                                                                // onClick={gotoConvFeeOption}
                                                            >Proceed for Payment
                                                            </Link>
                                                    }

                                                    {/*check the registration fee status
                                                    1. if Paid then show Already Paid and display the amount
                                                    2. if unpaid then show pay first option then goto step 3
                                                    3. after successfull payment all the information stored into the local storage and change the state.*/}


                                            </div>


                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">&nbsp;</div>
                </div>
            </div>
        </>
    )
}

export default  StudentApplied;