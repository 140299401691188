import React, {useEffect, useState} from "react";
import {ReactSession} from "react-client-session";
import {useHistory} from "react-router-dom";


const ArrowHeader=(props)=>{
    const history=useHistory();
    ReactSession.setStoreType("localStorage");
    const Logout=()=>{
        localStorage.clear();
        history.push('/');
    }
    const [isLogin,setIsLogin]=useState(false);
    useEffect(()=>{
        getLoginStatus();
    })
    const getLoginStatus=()=>{
        if(ReactSession.get("student_id")){
            setIsLogin(true)
        }
    }
    const login=()=>{
        history.push('/')
    }
    return(
        <div className="container-fluid text-center mt-5 border-bottom">
            {
                isLogin?
                <button className="btn btn-danger btn-sm mb-2 mx-5 left-align" onClick={Logout}>Logout</button>
                    :
                    <button className="btn btn-success btn-sm mb-2 mx-5 left-align" onClick={login}>Login</button>
            }

            <button className="btn btn-success mb-2 mx-1" disabled={props.form==="basic"?false:true}>Basic Information</button>
            <button className="btn btn-warning mb-2 mx-1" disabled={props.form==="detail"?false:true}>Personal Information</button>
            <button className="btn btn-info mb-2 mx-1" disabled={props.form==="school"?false:true}>Previous School </button>
            <button className="btn btn-primary mb-2 mx-1" disabled={props.form==="academic"?false:true}>Academic Details </button>
            <button className="btn btn-dark mb-2 mx-1" disabled={props.form==="stream"?false:true}>Apply for Stream </button>
            <button className="btn btn-success mb-2 mx-1" disabled={props.form==="pay"?false:true}>Payment Details</button>
            <button className="btn btn-danger mb-2 mx-1" disabled={props.form==="confirm"?false:true}>Confirm the Details</button>
        </div>
    )
}

export default ArrowHeader;