import React from 'react';

const Welcome=()=>{
    //console.log(data);
    return(
        <>
        </>
    )
}

export default Welcome;