import React from 'react';
import {ReactSession} from "react-client-session";
// import {useHistory} from "react-router-dom";

export function IsValidLogin(){
    // var history=useHistory();
    ReactSession.setStoreType("localStorage");
    if(localStorage.length===0){
        return false
    }
    else if(ReactSession.get("login_status")!=="true"){
        return false
    }
    else if(ReactSession.get("student_id")===null)
    {
        alert("You are not logged in please do login");
        return false;
    }
    return true;
}