import React, {useEffect, useState} from 'react';
import Axios from 'axios';
import {ReactSession} from "react-client-session";
import dotenv from "dotenv";
import dateFormat from "dateformat";
import {useHistory} from "react-router-dom";
import {IsValidLogin} from "../isLoggedIn";
import apply from "../apply/apply";
dotenv.config();

const PrintApplication=(props)=>{
    const history=useHistory();
    ReactSession.setStoreType("localStorage");
    const [studentDetail,setStudentDetail]=useState({});
    const [studentb,setStudentB]=useState({});
    const [studentd,setStudentD]=useState({});
    const [convFee,setConvFee]=useState([{}]);
    const [regFee,setRegFee]=useState([{}]);
    const [streamid,setStreamID]=useState(0);
    const [college_id,setCollege_ID]=useState(0);
    const [appid,setAppID]=useState('');
    useEffect(()=>{
        if(!IsValidLogin()){
            // console.log("entered");
            localStorage.clear();
            history.push("/");
        }

        setStreamID(props.match.strid);
        setCollege_ID(props.match.college_id)
        getStudentDetail();
        setStudentB(studentDetail.studentb);
        setStudentD(studentDetail.studentd);
        getRegid();
        getAcademicDetails();
        getSubjectDetails();
    },[]);
    const getAllDetails=()=>{
        console.log(studentb)
    }

    const [subjectDetails,setSubjectDetails]=useState({
        core1:"", core2:'', optional1:'',stream:'',group:'',
    })
    const getSubjectDetails=async ()=>{
        const uri=`${process.env.REACT_APP_URI}student/selected/subject/${ReactSession.get("student_id")}/${props.match.strid}`;
        const data=await Axios.get(uri).then((res)=>{
            console.log(res.data);
            setSubjectDetails(res.data)
        }).catch(err=>{
            console.log(err.message)
        })
    }
    const [college,setCollege]=useState('');
    const getStudentDetail=async ()=>{
        console.log(props);
        const uri=`${process.env.REACT_APP_URI}student/confirm/detail/${ReactSession.get("student_id")}/${props.match.college_id}/${props.match.strid}`;
        console.log(uri)

        const studentDetails=await Axios.get(`${uri}`)
            .then((res)=>{
                console.log(res.data);
                var {studentb,studentd,conFee,prosFee}=res.data;
                ReactSession.set("Category",res.data.prosFee[0].category_name);
                setStudentDetail(res.data);
                setConvFee(conFee);
                setStudentB(studentb);
                setStudentD(studentd);
                setRegFee(prosFee);
                getAcademicDetails();

        }).catch(err=>{
            console.log(err.message);
            })
    }
    const [aggregateSubjectDetails,setAggregateSubjectDetails]=useState({});

    const [acadDetails,setAcadDetails]=useState({})
    const [completeSubjectsDetails,setCompleteSubjectsDetails]=useState([{}])
    const getAcademicDetails=async ()=>{
        const acadDetails1=await Axios.get(`${process.env.REACT_APP_URI}student/academic/marks/detail/${ReactSession.get("student_id")}`)
            .then((res)=>{
                console.log(res.data)
                ReactSession.set("Previous_school",res.data.subjectDetails[0].school_name)
                ReactSession.set("Passing_Year",res.data.subjectDetails[0].pass_year)
                ReactSession.set("board_name",res.data.subjectDetails[0].board_name)
                setAcadDetails(res.data.subjectDetails);
                setCompleteSubjectsDetails(res.data.completeSubjectDetails)
            }).catch(err=>{
                console.log(err.message)
            })

    }

    const getRegid=async ()=>{
        var currentYear =  (new Date()).getFullYear();
        var twoLastDigits = currentYear%100;
        var formatedTwoLastDigits = "";

        if (twoLastDigits <10 ) {
            formatedTwoLastDigits = "0" + twoLastDigits;
        } else {
            formatedTwoLastDigits = "" + twoLastDigits;
        }
        var formatedStudentId = "";
        if(ReactSession.get("student_id")<10){
            formatedStudentId="000"+ReactSession.get("student_id")
        }
        else if(ReactSession.get("student_id")<100){
            formatedStudentId="00"+ReactSession.get("student_id")
        }else if(ReactSession.get("student_id")<1000){
            formatedStudentId="0"+ReactSession.get("student_id")
        }
        else{
            formatedStudentId=ReactSession.get("student_id");
        }
        if(ReactSession.get("college_code")!==undefined && ReactSession.get("stream_id")!==undefined) {
            var appid= formatedTwoLastDigits.toString()+ReactSession.get("college_code")+ReactSession.get("stream_id")+formatedStudentId.toString();
            console.log(appid)
            setAppID(appid);
            ReactSession.set("AppCode",appid)
        }
        console.log("Applied Application number:",appid);
        await Axios.put(`${process.env.REACT_APP_URI}student/stream/app/${ReactSession.get("student_id")}/${ReactSession.get("stream_id")}/${ReactSession.get("college_id")}`,{
            application_no:appid,
        }).then((res)=>{
            console.log("Application ID Updated Successfully")
        })
    }



    function printDiv(divName) {
        var printContents = document.getElementById(divName).innerHTML;
        var originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    }

    return(
        <>
            <div className="container mt-4" id="printableArea">
                <div className="row p-2">
                    <div className="col-md-12">
                        <fieldset className="border">
                            {
                                studentb?
                                    <></>:
                                    <h1>Please wait.. we're fetching the data</h1>
                            }

                            <table className="table table-bordered">
                                <thead>
                                <tr>
                                    <th colSpan={4}><legend className="fw-bold" > {ReactSession.get("college_name")} </legend></th>
                                </tr>
                                <tr>
                                    <td colSpan={4}>
                                        <table className="table table-bordered">
                                            <tr>
                                                <td><legend>Application No. : {ReactSession.get("Application_ID")}</legend></td>
                                                <td><legend>Course Applied :</legend> {subjectDetails.stream_name}:-{subjectDetails.core1},
                                                    {subjectDetails.core2}, {subjectDetails.optional1},
                                                    {subjectDetails.group}</td>
                                            </tr>
                                        </table>
                                    </td>

                                </tr>
                                <tr>
                                    <td width="20%">Student Name:</td>
                                    <td>
                                        {
                                            studentb?
                                                <label className="fw-bold text-dark mt-1">{studentb.student_name}</label>
                                                :
                                                <label></label>
                                        }
                                    </td>
                                </tr>

                                <tr>
                                    <td width="20%">Father's Name:</td>
                                    <td>
                                        {
                                            studentb?
                                                <label className="fw-bold text-dark col-md-9 mt-1">{studentb.student_fatherName}</label>
                                                :
                                                <label></label>
                                        }
                                    </td>
                                </tr>

                                <tr>
                                    <td width="20%">Mother's Name:</td>
                                    <td>
                                        {
                                            studentd?
                                                <label className="fw-bold text-dark col-md-9 mt-1">{studentd.student_mothername}</label>
                                                :
                                                <label></label>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td width="20%">Gender:</td>
                                    <td>
                                        {
                                            studentb?
                                                <label className="fw-bold text-dark col-md-9 mt-1">{studentb.student_gender}</label>
                                                :
                                                <label></label>
                                        }
                                    </td>
                                </tr>
                                    <tr>
                                        <td width="20%">Date of Birth:</td>
                                        <td>
                                            {
                                                studentb?
                                                    <label className="fw-bold text-dark col-md-9 mt-1">{dateFormat(studentb.student_dob,"dd/mmm/yyyy")}</label>
                                                    :
                                                    <label></label>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="20%">Email ID:</td>
                                        <td>
                                            {
                                                studentb?
                                                    <label className="fw-bold text-dark col-md-9 mt-1">{studentb.student_email}</label>
                                                    :
                                                    <label></label>
                                            }
                                        </td>
                                    </tr>
                                <tr>
                                    <td width="20%">Mobile No.:</td>
                                    <td>
                                        {
                                            studentb?
                                                <label className="fw-bold text-dark col-md-9 mt-1">{studentb.student_mobile}</label>
                                                :
                                                <label></label>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td width="20%">Address:</td>
                                    <td>
                                        {
                                            studentd?
                                                <label className="fw-bold text-dark col-md-9 mt-1">{studentd.student_address}</label>
                                                :
                                                <label></label>
                                        }
                                    </td>
                                </tr>

                                <tr>
                                    <td width="20%">Last Attended School:</td>
                                    <td>
                                        {
                                            // console.log(acadDetails)
                                            ReactSession.get("Previous_school")!==undefined?
                                                <label className="fw-bold text-dark col-md-9 mt-1">{ReactSession.get("Previous_school")}</label>
                                                :
                                                <label>no record</label>
                                        }
                                    </td>
                                </tr>

                                <tr >
                                    <td colSpan={2}>
                                    <table className="table table-border">
                                        <tr>
                                            <td width="20%">Board Name:</td>
                                            <td>
                                                {
                                                    // console.log(acadDetails)
                                                    ReactSession.get("board_name")!==undefined?
                                                        <label className="fw-bold text-dark col-md-9 mt-1">{ReactSession.get("board_name")}</label>
                                                        :
                                                        <label>no record</label>
                                                }
                                            </td>
                                            <td width="20%">Year of Passing:</td>
                                            <td>
                                                {
                                                    // // console.log(acadDetails)
                                                    ReactSession.get("Passing_Year")!==undefined?
                                                        <label className="fw-bold text-dark col-md-9 mt-1">{ReactSession.get("Passing_Year")}</label>
                                                        :
                                                        <label>no record</label>
                                                }
                                            </td>
                                        </tr>
                                    </table>
                                    </td>

                                </tr>
                                <tr>
                                    <td width="20%">Aadhar No.:</td>
                                    <td>
                                        {
                                            studentd?
                                                <label className="fw-bold text-dark col-md-9 mt-1">{studentd.student_aadhar}</label>
                                                :
                                                <label></label>
                                        }
                                    </td>
                                </tr>

                                <tr>
                                    <td width="20%">Category:</td>
                                    <td>
                                        {
                                            regFee?
                                                <label className="fw-bold text-dark col-md-9 mt-1">{regFee[0].category_name}</label>
                                                :
                                                <label></label>
                                        }
                                    </td>
                                </tr>
                                </thead>

                            </table>
                            {
                                completeSubjectsDetails.length>4?
                                    <>
                                        <div className="bg-secondary text-light fw-bold p-2">
                                            <label >
                                                Subject details
                                            </label>
                                        </div>
                                        {/**/}
                                        <table className="table table-hover table-bordered">
                                            <tr>
                                            {
                                                completeSubjectsDetails.map(item=>{
                                                    return(
                                                        <th width="20%">{item.subject_name}<br/>{item.subject_marksobtained}</th>
                                                    )
                                                })
                                            }
                                            </tr>
                                            <tr>
                                                <td colSpan={5}>
                                                    <table className="table table-bordered">
                                                        <tr>
                                                            {
                                                                acadDetails.map(item=>{
                                                                    return(
                                                                        <>
                                                                            <th width="20%">Marks Obtained</th>
                                                                            <td  width="20%">{parseFloat(item.average_marks).toFixed(2)}</td>
                                                                            <th  width="35%">Marks Obtained/Max. Marks</th>
                                                                            <td width="25%">{item.total_marks}/{item.total_maxmarks}</td>
                                                                        </>
                                                                    )
                                                                })

                                                            }
                                                        </tr>
                                                    </table>

                                                </td>
                                            </tr>
                                        </table>
                                    </>
                                    :
                                    <div>
                                        Please contact to Administrator
                                    </div>
                            }



                                    {
                                        convFee.length>0?
                                            <>
                                                <div className="bg-secondary text-light fw-bold p-2">
                                                    <label >
                                                        Prospectus Fee Details
                                                    </label>
                                                </div>
                                                {/**/}
                                                <table className="table table-hover table-bordered">
                                                    <tr>
                                                        {/*<th>S.No.</th>*/}
                                                        <th>Transaction ID</th>
                                                        <th>Stream Name</th>
                                                        <th>Amount Paid</th>
                                                        <th>Transaction Date</th>
                                                    </tr>
                                                    {
                                                        regFee.map(item=>{
                                                            ReactSession.set("stream_id",item.stream_id);
                                                            ReactSession.set("college_id",item.college_id);
                                                            ReactSession.set("college_name",item.college_name);
                                                            ReactSession.set("college_code",item.college_code);
                                                            ReactSession.set("amount",item.paid_amount);
                                                            ReactSession.set("stream_name",item.stream_name);
                                                            return( <tr>

                                                                <td>reg_{item.paymentdetail_id}</td>

                                                                <td>{item.stream_name}</td>
                                                                <td>{item.paid_amount}</td>
                                                                <td>{dateFormat(item.createdAt,"dd/mm/yyyy HH:MM:ss")}</td>
                                                            </tr>)
                                                        })
                                                    }
                                                </table>
                                            </>
                                            :
                                            <div>
                                                Please contact to Administrator
                                            </div>
                                    }


                                </fieldset>
                                </div>
                                </div>
                                </div>
            <div className="m-3 text-center">
                <button type={"button"} className="btn btn-primary btn-sm" onClick={()=>printDiv('printableArea')} ><i className="fa fa-print"></i>Print Application Form</button>
            </div>
                                </>
                                )

                            }

export default PrintApplication;